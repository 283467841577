import Ajax from './ajax';

const Request = {
  User: {
    info() {
      return Ajax.get('/v2/ajax.php?act=userinfo');
		// return Ajax.get('/api/account/info');
    },
	index(param){
		 return Ajax.post('/v2/ajax.php?act=userlist', param);
	},
	add(param){
		return Ajax.post('/v2/ajax.php?act=add_user', param);
	},
	cz(param){
		return Ajax.post('/v2/ajax.php?act=user_cz', param);
	},
	pass(param){
		return Ajax.post('/v2/ajax.php?act=passwd', param);
	}
  },
  Order:{
  	index(param){
  		 return Ajax.post('/v2/ajax.php?act=orderlist', param);
  	},
  	add(param){
  		 return Ajax.post('/v2/ajax.php?act=add', param);
  	},
  	get(param){
  		 return Ajax.post('/v2/ajax.php?act=get', param);
  	},
	get_class(param){
		 return Ajax.post('/v2/ajax.php?act=get_class', param);
	},
	budan(param){
		 return Ajax.post('/v2/ajax.php?act=order_budan', param);
	}
  },
Log:{
	index(param){
		 return Ajax.post('/v2/ajax.php?act=loglist', param);
	}
},
  Login: {
    login(param) {
      return Ajax.post('/v2/ajax.php?act=login', param);
    },
    logout(param) {
      return Ajax.post('/logout', param);
    }
  },
  Dict: {
    get() {
      return Ajax.get(`/dict`);
    }
  },
  Home: {
    getMessageList() {
      return Ajax.get(`/home/messages`);
    }
  },
  Account: {
    menus() {
    }
  },
  Management: {
    users(params) {
      return Ajax.get('/management/users', params);
    },
    roles(params) {
		return {
  status: 200,
  body: [
    {
      id: 1,
      name: '系统管理员',
      description: '最高权限，可操作任何页面和功能'
    },
    {
      id: 2,
      name: '普通员工',
      description: '普通员工'
    },
    {
      id: 3,
      name: 'Leader',
      description: '可进行团队数据查看，可导出数据'
    }
  ]
}
      // return Ajax.get('/management/roles', params);
	  
	  
	  
	  
    }
  }
};

export default Request;
